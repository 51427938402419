<template>
  <div class="drawer">
    <div class="empty"></div>
    <div
      class="digon"
      @click="digon"
      @mouseover="colorOver"
      @mouseout="colorOut"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        :class="{ hove: show === item }"
      >
        <span :class="{ liHove: show === item, mouse: vae === item }"></span>
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import bus from "../../../utils/index";
const router = useRouter();
const data = [
  "交易保障",
  "生活服务",
  "医疗健康",
  "宠物消费",
  "智能硬件",
  "运动健身",
  "灵活用工",
  "出行旅游",
  "企业服务",
  "餐饮消费",
  "金融保障",
  "智慧社区",
  "防伪溯源",
];
const mapping = {
  交易保障: "Transaction-guarantee",
  生活服务: "Life-service",
  医疗健康: "Medical-health",
  宠物消费: "Pet-consumption",
  智能硬件: "Intelligent-hardware",
  运动健身: "Exercise-fitness",
  灵活用工: "Flexible-hardworking",
  出行旅游: "Travel-Tourism",
  企业服务: "Enterprise-services",
  餐饮消费: "Catering-consumption",
  金融保障: "Financial-security",
  智慧社区: "Smart-community",
  防伪溯源: "Anti-counterfeiting-traceability",
};
const contrary = {
  "Transaction-guarantee": "交易保障",
  "Life-service": "生活服务",
  "Medical-health": "医疗健康",
  "Pet-consumption": "宠物消费",
  "Intelligent-hardware": "智能硬件",
  "Exercise-fitness": "运动健身",
  "Flexible-hardworking": "灵活用工",
  "Travel-Tourism": "出行旅游",
  "Enterprise-services": "企业服务",
  "Catering-consumption": "餐饮消费",
  "Financial-security": "金融保障",
  "Smart-community": "智慧社区",
  "Anti-counterfeiting-traceability": "防伪溯源",
};
let show = ref("");
let vae = ref("");
onMounted(() => {
  let a = router.currentRoute.value.query.id;
  if (a) {
    show.value = contrary[a];
  }
});
//监听路由变化，路由变化。若选中了悬浮框中选项则添加样式，若从解决方案路由离开，则清除选项的选中状态
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    if (newValue === "/transaction") {
      const a = router.currentRoute.value.query.id;
      show.value = contrary[a];
      return;
    }
    if (newValue !== oldValue) {
      show.value = "";
    }
  }
);
//点击哪个选型，哪个选型就添加选中样式
const digon = (e) => {
  router.push({
    path: "/transaction",
    query: { id: mapping[e.target.innerText.trim()] },
  });
  show.value = e.target.innerText.trim();
  // setTimeout(()=>{bus.emit('transaction')},500)
  bus.emit("transaction");
};
//鼠标经过小圆点改变背景色
const colorOver = (e) => {
  if (e.target.innerText.length !== 5) return;
  vae.value = e.target.innerText.trim();
};
//鼠标离开小圆点改变背景色
const colorOut = (e) => {
  if (e.target.innerText.length !== 5) return;
  vae.value = "";
};
</script>

<style scoped>
.drawer {
  position: absolute;
  top: 24px;
  left: 28%;
  width: 488px;
  height: 180px;
  background: #fff;
}
.empty {
  height: 15px;
  background: #fff;
}
.drawer .digon {
  display: flex;
  flex-wrap: wrap;
  padding-top: 13px;
}
.drawer .digon div {
  width: 25%;
  list-style: none;
  text-align: center;
  padding: 7px 0 8px 0;
}
.drawer .digon div:hover {
  color: #ff8a07;
  cursor: pointer;
}
.drawer .digon div span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin: 0 7px 3px 0;
  background: linear-gradient(180deg, #dddddd 0%, #b8b8b8 100%);
}
.hove {
  color: #ff8a07;
}
.liHove {
  background: linear-gradient(136deg, #fe9f0c 0%, #ff7201 100%) !important;
}
.mouse {
  background: linear-gradient(136deg, #fe9f0c 0%, #ff7201 100%) !important;
}
</style>

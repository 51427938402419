<template>
  <div class="nav">
    <div class="mag">
      <div class="foot-down">
        <div class="left">
          <div>
            <!-- <img src="../../../assets/img/logo.png" alt=""> -->
            <img v-lazy="imgObj" alt="" />
          </div>
          <ul class="up" @click="footTrum">
            <li
              v-for="(item, index) in tab"
              :key="index"
              :class="{ crlf: cla === item }"
            >
              {{ item }}
            </li>
          </ul>
          <div class="down">
            <p>
              ©2019
              <a href="https://beian.miit.gov.cn/" target="_blank">
                粤ICP备2020092206号</a
              >
            </p>
            <p>邮箱：vintoli@insurebao.com</p>
            <p>
              地址：深圳市南山区粤海街道高新区社区高新南一道013号赋安科技大楼B座
            </p>
          </div>
        </div>
        <div class="right">
          <!-- <img src="../../../assets/img/gzhewm.png" alt=""> -->
          <img v-lazy="imgObj2" alt="" />
          <div class="icon">
            <!-- <img src="../../../assets/img/saoma_icon.png" alt=""> -->
            <img v-lazy="imgObj3" alt="" />
            <span>扫码进入超能保展业平台</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import bus from "../../../../utils/index";
import { watch, ref } from "vue";
import { useRouter } from "vue-router";
const imgObj = require("../../../assets/img/logo@2x.png");
const imgObj2 = require("../../../assets/img/home/ewm.png");
const imgObj3 = require("../../../assets/img/saoma_icon.png");
const router = useRouter();
const tab = ["为什么选择我们", "行业解决方案", "服务支持", "关于秦保"];
let cla = ref("");
//监听路由变化，加上选中样式
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      switch (newValue) {
        case "/":
          cla.value = "为什么选择我们";
          break;
        case "/home":
          cla.value = "为什么选择我们";
          break;
        case "/transaction":
          cla.value = "行业解决方案";
          break;
        case "/service":
          cla.value = "服务支持";
          break;
        case "/about":
          cla.value = "关于秦保";
          break;
      }
    }
  },
  { immediate: true }
);
//点击哪个选项就加上选中样式并跳转
const footTrum = (e) => {
  switch (e.target.innerText) {
    case "为什么选择我们":
      router.push("/");
      cla.value = "为什么选择我们";
      setTimeout(() => {
        bus.emit("home");
      }, 400);
      break;
    case "行业解决方案":
      router.push({
        path: "/transaction",
        query: { id: "Transaction-guarantee" },
      });
      cla.value = "行业解决方案";
      setTimeout(() => {
        bus.emit("transaction");
      }, 500);
      break;
    case "服务支持":
      router.push("/service");
      cla.value = "服务支持";
      setTimeout(() => {
        bus.emit("service");
      }, 400);
      break;
    case "关于秦保":
      router.push("/about");
      cla.value = "关于秦保";
      setTimeout(() => {
        bus.emit("about");
      }, 400);
      break;
    default:
      router.push("/");
      cla.value = "为什么选择我们";
      setTimeout(() => {
        bus.emit("home");
      }, 400);
      break;
  }
};
</script>

<style scoped>
.nav {
  width: 100%;
  background-color: #f7f7f9;
}
.mag {
  width: 1200px;
  margin: 0 auto;
}
.foot-down {
  position: relative;
  height: 472px;
}
.left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.left img {
  width: 159px;
  height: 45px;
}
.up {
  display: flex;
}
.up li {
  list-style: none;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #848484;
  line-height: 22px;
  margin: 45px 60px 0 0;
}
.up li:hover {
  cursor: pointer;
  color: #2a2a2a;
}
.down {
  width: 600px;
  padding-top: 20px;
  margin-top: 25px;
  border-top: 1px solid #e2e2e2;
}
.down p {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 20px;
  margin-bottom: 13px;
}
.down a {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 20px;
  text-decoration: none;
}
.right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.right img {
  width: 180px;
  height: 180px;
  vertical-align: middle;
}
.right .icon {
  margin-top: 15px;
  color: #848484;
}
.right .icon img {
  width: 21px;
  height: 21px;
  margin-right: 8px;
}
.crlf {
  color: #2a2a2a !important;
}
</style>

import { createRouter, createWebHashHistory } from "vue-router";
// import Home from '../page/home/index.vue'
// import Transaction from "../page/Transaction/index.vue";
// import Service from "../page/service/index.vue";
// import About from '../page/about/index.vue'
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../page/home/index.vue"),
    // component: Home,
    meta: { KeepAlive: true },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("../page/Transaction/index.vue"),
    // component: Transaction,
    meta: { KeepAlive: true },
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../page/service/index.vue"),
    // component: Service,
    meta: { KeepAlive: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../page/about/index.vue"),
    // component: About,
    meta: { KeepAlive: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  routes,
});

export default router;
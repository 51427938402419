<template>
        <div class="foot-up">
            <img v-lazy="imgObj" alt="">
            <div class="nav">
            <p class="big">我知道你已经迫不及待了~</p>
            <p class="small">请马上联系我们，会在第一时间给您回复，我们将为您提供独一无二的服务~</p>
            <button @click="appointment">立即预约体验</button>
            </div>
        </div>
</template>

<script setup>
const imgObj = require('../../../assets/img/lx_bg.png')
const appointment = ()=>{
    window.location.href = 'https://www.wjx.cn/vm/rruZ6pb.aspx'
}
</script>

<style scoped>
.foot-up{
    position: relative;
    /* height: 300px; */
    /* background:url('../../../assets/img/lx_bg.png') no-repeat;
    background-size:100% 100%; */
}
.foot-up img{
    height: 300px;
    width: 100%;
    vertical-align: bottom;
}
.foot-up .nav{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.foot-up .big{
    height: 48px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 48px;
    padding-bottom: 11px;
}
.foot-up .small{
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgba(255, 255, 255,.6);
    line-height: 25px;
    padding-bottom: 50px;
}
.foot-up button{
    width: 150px;
    height: 40px;
    color: #fff;
    background: linear-gradient(136deg, #FE9F0C 0%, #FF7201 100%);
    border-radius: 20px;
    border: none;
}
.foot-up button:hover{
    background: linear-gradient(136deg, #F2B453 0%, #FD9644 100%);
    cursor: pointer;
}
</style>
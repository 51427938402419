<template>
    <Empty v-if="show"></Empty>
    <div v-if="!show">
        <Header></Header>
                <router-view v-slot="{ Component }">
                <transition name="anima" mode="out-in">
                    <component :is="Component" />
                </transition>
                </router-view>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import Header from './components/Heade/index.vue'
import Footer from './components/foot/index.vue'
import Empty from './components/empty.vue'

let show = ref(true)
window.onload = () => {
    show.value = false
}
</script>

<style scoped>
.anima-leave-active,
.anima-enter-active {
    transition: all 0.3s;
}
.anima-enter {
    opacity: 0;
    transform: translateX(-30px);
}
.anima-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import UILib from './components/ScrollNumber/index'

import VueLazyload from "vue-lazyload";
// import { imgsPreloader } from './Picture-preload/imgPreloader'
// import imgsPreloaderList from './Picture-preload/imgPreloaderList'

// (
//     async()=>{
//         await imgsPreloader(imgsPreloaderList)
//         console.log('图片加载完成');
//         const app = createApp(App);
//         app.use(router);
//         app.use(UILib);
//         app.mount("#app");
//     }
// )()
const app = createApp(App);
app.use(router);
app.use(UILib);
app.use(VueLazyload)
app.mount("#app");


<template>
    <div class="empty">
        <div class="conxt">
            <!-- <img src="" alt=""> -->
            <h4>请稍等，页面正在努力加载中</h4>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.empty{
    position: relative;
    width: 100vw;
    height: 100vh;
}
.conxt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
</style>
<template>
<div class="nav">
    <div class="dop">
    <div class="header">
        <!-- <img src="../../assets/img/logo.png"/> -->
        <img v-lazy="imgObj"/>
    </div>
    <div class="mag">
    <div class="center">
        <ul class="uli" @click="trum">
            <li :class="{firstHove:sty==='为什么选择我们'}">为什么选择我们</li>
            <div @mousemove="over" @mouseout="out">
            <li :class="{firstHove:sty==='行业解决方案'}">
                行业解决方案
            </li>
            <transition name='fade'>
                <Drawer v-show="isshow"></Drawer>
            </transition>
            </div>
            <li :class="{firstHove:sty==='服务支持'}">服务支持</li>
            <li :class="{firstHove:sty==='关于秦保'}">关于秦保</li>
        </ul>
    </div>
    <div class="foot">
        <button><a href="https://www.qbao100.com/management/#/user/login">去登录~</a></button>
    </div>
    </div>
    </div>
</div>
</template>

<script setup>
import {ref,watch} from 'vue'
import {useRouter} from 'vue-router'
import Drawer from '../drawer/index.vue'
import bus from '../../../utils/index'
const imgObj = require('../../assets/img/logo@2x.png')
const router = useRouter()
let isshow = ref(false)
let sty = ref('')
//监听路由的变化，给对应选项加上选中样式
watch(() =>router.currentRoute.value.path,(newValue,oldValue)=> {
        if(newValue !== oldValue){
            switch(newValue){
                case '/':
                    sty.value = '为什么选择我们'
                    break;
                case '/home':
                    sty.value = '为什么选择我们'
                    break;
                case '/transaction':
                    sty.value = '行业解决方案'
                    break;
                case '/service':
                    sty.value = '服务支持'
                    break;
                case '/about':
                    sty.value = '关于秦保'
                    break;
            }
        }
},{ immediate: true })
// 点击选项，让目标结点为选中状态，其他结点清除选中状态样式
const trum = (e)=>{
    switch(e.target.innerText){
        case '为什么选择我们':
            router.push('/')
            sty.value = '为什么选择我们'
            setTimeout(()=>{bus.emit('home')},400)
            break;
        case '行业解决方案':
            router.push({path:'/transaction',query:{id:'Transaction-guarantee'}})
            sty.value = '行业解决方案'
            setTimeout(()=>{bus.emit('transaction')},500)
            break;
        case '服务支持':
            router.push('/service')
            sty.value = '服务支持'
            setTimeout(()=>{bus.emit('service')},400)
            break;
        case '关于秦保':
            router.push('/about')
            sty.value = '关于秦保'
            setTimeout(()=>{bus.emit('about')},400)
            break;
    }
}
//鼠标移入，二级菜单显示
const over = ()=>{
    isshow.value = true
}
//鼠标移出，二级菜单隐藏
const out = ()=>{
    isshow.value = false
}
</script>

<style scoped>
.nav{
    position: fixed;
    width: 100vw;
    top: 0px;
    z-index: 99;
    background-color: #fff;
}
.dop{
    height: 80px;
    position: relative;
    z-index: 999;
}
.header{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 1%;
}
.header img{
    width: 159px;
    height: 45px;
}
.mag{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.center ul{
    display: flex;
    margin-left: 280px;
}
.center ul li{
    list-style: none;
    padding: 0 42.5px 0 42.5px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
}
.center ul li:hover{
    color: #ff8a07;
    cursor: pointer;
}
.foot{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.foot button{
    width: 130px;
    height: 34px;
    border: none;
    background: linear-gradient(136deg, #FE9F0C 0%, #FF7201 100%);
    border-radius: 17px;
}
.foot a{
    text-decoration-line: none;
    color: #fff;
}
.foot :hover{
    cursor: pointer;
}
.firstHove{
    color: #ff8a07;
}
.fade-enter-active, .fade-leave-active{
    transition: opacity 0.5s ease;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-from,
.fade-leave-to{
    opacity: 0;
}
</style>